<template>
  <div class="forget-password">
    <h1 class="title">
      Forget Password
    </h1>
    <v-divider />

    <p class="subtitle pa-2">
      You need to know your email. If you can't remember your email. Please contact our support center.
    </p>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="4">
          <v-subheader>Email</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field
            required
            type="email"
            label="Your Email"
            v-model="email"
          />
        </v-col>
      </v-row>
      <v-row
        class="justify-end"
        no-gutters
      >
        <v-btn
          outlined
          color="primary"
          to="/"
        >
          Back to Login
        </v-btn>
        <v-spacer />
        <v-btn
          :loading="loading"
          type="submit"
          color="info"
        >
          Request Password Reset
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
export default {
  name: "ForgetPassword",
  data (){
    return {
      email: "",
      loading: false
    }
  },
  methods: {
    async onSubmit () {
      try {
        this.loading = true
        const response = await this.$store.dispatch("forgotPassword", this.email)
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.email = ''
        this.loading = false
      } catch (e) {
        console.log(e.response)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.forget-password {
  margin: 0 auto;
  max-width: 600px;
  margin-top: 24px;
}
</style>